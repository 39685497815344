import CategoriesList from "../CategoriesList";

export default {
    name: 'index',
    components: {
        CategoriesList
    },
    props: [],
    data() {
        return {

        }
    },
    computed: {},
    mounted() {

    },
    methods: {
        createCategory() {

        }
    }
}


