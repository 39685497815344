export default {
  NameError() {
    if (!this.$v.category.Name.$anyError) return null;
    else if (!this.$v.category.Name.required) return "required_field";
    else return null;
  },
  DescriptionError() {
    if (!this.$v.category.Description.$anyError) return null;
    else if (!this.$v.category.Description.required) return "required_field";
    else return null;
  },

  //endregion
};
