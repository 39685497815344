import Validations from './Validations';
import Errors from './Errors';
import SMSTService from '@/services/sms.template.service';

const smsTService = new SMSTService();
export default {
  name: 'categories-list',
  components: {},
  props: [],
  data() {
    return {
      category: {
        Name: '',
        Description: '',
        Color: '#ff56ee45',
        UseCase: 'INDIVIDUAL',
        Language: 'ENGLISH',
        Share: false,
        Active: true,
      },
      categories: [],
      categorySelected: {},
      selectedForRemove: [],
      UseCaseOptions: [
        {
          text: 'Campaign',
          value: 'CAMPAIGN'
        },
        {
          text: 'Individual',
          value: 'INDIVIDUAL'
        }
      ],
      LanguagesOptions: [
        {
          text: 'English',
          value: 'ENGLISH'
        },
        {
          text: 'Spanish',
          value: 'SPANISH'
        }
      ],
      modal: {
        create: false,
        update: false,
        remove: false
      },
      perPage: 25,
      totalRows: 0,
      currentPage: 1
    }
  },
  validations: Validations,
  computed: {...Errors},
  async mounted() {
    await this.list();
  },
  methods: {
    async create() {
      await this.$v.$touch();
      if (this.$v.$invalid) return;

      const res = await smsTService.createCategory(this.category)
      if (res && !res.error) {
        this.closeModal();
        await this.list();
      }
    },

    async list() {
      const skip = this.perPage * (this.currentPage - 1);

      const res = await smsTService.listCategories(this.perPage, skip);
      if (res && !res.error) {
        this.categories = res.data;
        this.totalRows = res.data.count;
      }
    },

    async update() {
      const res = await smsTService.updateCategory(this.categorySelected)
      if (res && !res.error) {
        this.closeModal();
        await this.list();
      }
    },

    async removeOneCategory() {
      const res = await smsTService.removeOneCategory(this.categorySelected._id)
      if (res && !res.error) {
        this.closeModal();
        await this.list();
      }
    },

    async removeMultiplesCategory() {
      const res = await smsTService.removeMultiplesCategory(this.selectedForRemove)
      if (res && !res.error) {
        this.selectedForRemove = [];
        this.closeModal();
        await this.list();
      }
    },

    async selectCategory(category) {
      this.categorySelected._id = category._id;
      this.categorySelected = category;
      this.modal.update = true;
    },

    closeModal() {
      this.modal = {
        create: false,
        update: false,
        remove: false
      };
      this.category = {
        Name: '',
        Description: '',
        Color: '',
        UseCase: '',
        Active: true,
      };
      this.categorySelected = {};
      this.errors = [];
    },

    showRemoveModal(category) {
      this.categorySelected = category
      this.modal.remove = true;
    },

    selectItemsForRemove(id) {
      const index = this.selectedForRemove.indexOf(id)
      index !== -1 ? this.selectedForRemove.splice(index, 1) : this.selectedForRemove.push(id);
    },

    selectAllItemsForRemove() {
      //TODO: Select all items in [categories] for delete.
    },

    showModalRemove(id) {
      this.modal.remove = true;
      this.selectItemsForRemove(id)
    },
  }
}


